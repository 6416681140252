@import 'globalStyle/GLOBAL';

.container {
  max-width: 100%;
  margin: 0 50px 50px 50px;
  animation: animationContainerOpacityIn $GLOBAL_animationTime;
}

@include mediaSmartphone {
  .container {
    margin: 0 18px 25px 18px;
  }
}

@keyframes animationContainerOpacityIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
