@import 'globalStyle/GLOBAL';

.componentContainer {
  display: inline-block;
  padding: 20px;
  width: 100%;
}

.oopsTextContainer, .errorTextContainer {
  font-family: $GLOBAL_fontName;
  color: $GLOBAL_fontColor;
}

.oopsTextContainer {
  font-size: $GLOBAL_fontSizeBlockContainerTitle;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.errorTextContainer {
  font-size: $GLOBAL_fontSizeSecondary;
  line-height: 19px;
  text-align: center;
  white-space: pre-wrap;
}
