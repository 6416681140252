@import 'globalStyle/GLOBAL';

$animationTime: .2s;

.mobileContainer {
  width: inherit;
  height: 100%;
  flex-direction: column;
  align-items: center;
  gap: 5px 0;
  justify-content: center;
  display: none;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.pageTitle {
  font-family: $GLOBAL_fontNameMedium;
  font-size: $GLOBAL_fontSizeAdditional;
  color: $GLOBAL_fontColorOnAnyBg;
  line-height: $GLOBAL_lineHeight;
  text-align: center;
}

.dropdown {
  position: relative;
  display: inline-block;

  .menuIconWrap {
    width: 22px;
    height: 22px;
    padding: 8px;
    color: $GLOBAL_fontColorOnAnyBg;
    cursor: pointer;
    transition: background-color $animationTime ease-in-out;

    &:hover {
      background-color: $GLOBAL_color_primary;
      border-radius: 5px;
    }
  }
}

.dropdownContent {
  display: none;
  position: absolute;
  right: 0;
  z-index: $GLOBAL_zIndex_5;
  background-color: $GLOBAL_fontColorOnAnyBg;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border-radius: $GLOBAL_borderRadiusContainer;
  min-width: 260px;
  padding: 10px;
  margin-top: 5px;
  animation: animationOpacityIn $animationTime;
  transition: all $animationTime linear;

  @keyframes animationOpacityIn {
    from {
      display: none;
      opacity: 0;
      visibility: hidden;
    }
    to {
      display: block;
      opacity: 1;
      visibility: visible;
    }
  }
}

.dropdownContent > a, .dropdownContent > div {
  color: $GLOBAL_fontColor;
  text-decoration: none;
  padding: 8px;
}

.dropdownContent > div svg {
  color: $GLOBAL_color_errorOrCancel;
}

.dropdownContent.active {
  display: block;
}

.menuIconWrap.activeHamburger {
  background-color: $GLOBAL_color_gray_10;
  border-radius: 5px;
}

.backIcon {
  color: $GLOBAL_fontColorOnAnyBg;
  height: 19px;
  width: 10px;
  cursor: pointer;
  border-radius: $GLOBAL_borderRadiusContainer;
  padding: 5px 9px;
  $animationTime: .2s;
  transition: background-color $animationTime ease-in-out;

  &:hover {
    background-color: $GLOBAL_color_primary;
  }
}

@include mediaSmartphone {
  .mobileContainer {
    display: flex;
  }
}
