@import 'globalStyle/GLOBAL';

.componentContainer {
  background-image: linear-gradient(to bottom, var(--main-background-color, $GLOBAL_color_headerBackground) 0%, $GLOBAL_bodyBgColor 100%);
  width: 100%;
  height: 220px;
  position: absolute;
  top: 70px;
  z-index: -1;
  animation: animationComponentContainerOpacityIn .3s;
}

@keyframes animationComponentContainerOpacityIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
