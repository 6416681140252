@import 'globalStyle/GLOBAL';

.mobileContainer {
  width: inherit;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px 0;
  justify-content: center;
  display: none;
}

@include mediaSmartphone {
  .mobileContainer {
    display: flex;
  }
}
