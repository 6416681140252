@import 'globalStyle/GLOBAL';

$animationTime: .2s;

.innerContainer {
  display: flex;
  align-items: center;
  gap: 7px;
}

.desktopContainer {
  width: inherit;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
  gap: 30px;
}

.companyLogoInTopMenuContainerDefault {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 20px 5px 20px 0;
}

.companyLogoInTopMenuContainerWithFilter {
  padding: 20px 5px;
  margin-left: -13px;
}

.filterExpansion {
  background-color: transparent;
  border-radius: 5px;
  width: 38px;
  height: 38px;
  margin-right: 30px;
  @include GLOBAL_mixin_flexCenter();
  cursor: pointer;
  transition: background-color $animationTime ease-in-out;
  position: relative;
  margin-bottom: 5px;
}

.companyNameInTopMenuContainer {
  font-family: $GLOBAL_fontNameBold;
  font-size: $GLOBAL_fontSizeAdditional;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-transform: uppercase;
  color: $GLOBAL_fontColorOnAnyBg;
}

.typeofUserInCabinet {
  font-size: 12px;
  text-transform: none;
  font-family: $GLOBAL_fontNameMedium;
  opacity: 0.7;
}

.companyLogoImgContainerLink {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;

  img {
    border-radius: 5px;
    max-width: 120px;
    max-height: 50px;
    display: inline-block;
    position: relative;
    margin-right: 10px;
    object-fit: cover;
  }
}

.rightBlockMenuContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;

  .topMenuNavigation {
    display: flex;
    align-items: center;
    gap: 30px;
  }
}

@keyframes animation_workingDateContainerOpacityIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@include mediaSmartphone {
  .desktopContainer {
    display: none;
  }
}
