@import 'globalStyle/GLOBAL';

.componentContainer {
  background-color: $GLOBAL_fontColorOnAnyBg;
  padding: 40px;
  border-radius: 10px;
}

@include mediaSmartphone {
  .componentContainer {
    padding: 20px 25px 25px 25px;
  }
}
