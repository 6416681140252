@import 'globalStyle/GLOBAL';

.personalDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.checkBoxContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > span {
    font-family: $GLOBAL_fontName;
    font-size: $GLOBAL_fontSizeHelper;
    line-height: 19px;
    text-align: center;

    & > a {
      color: $GLOBAL_color_primary;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.errorTextContainer {
  padding-top: 5px;
  color: $GLOBAL_color_errorOrCancel;
  font-size: $GLOBAL_fontSizeHelper;
  animation: animation_errorOpacityIn .2s;
}

@keyframes animation_errorOpacityIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

