@import 'globalStyle/GLOBAL';

$animationTime: .2s;

.errorTextContainer {
  padding-top: 5px;
  color: $GLOBAL_color_errorOrCancel;
  font-size: $GLOBAL_fontSizeHelper;
  animation: animationErrorOpacityIn $animationTime;
}

@keyframes animationErrorOpacityIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@include mediaSmartphone {
  .popupContainer {
    width: 90%;
    border-radius: 20px;
    height: auto;
  }
}

@include mediaSmallSmartphone {
  .popupContainer {
    width: 100%;
    height: 100%;
    border-radius: unset;
  }
}
