@import 'globalStyle/GLOBAL';

.messageContainer {
  text-align: center;
  font-size: $GLOBAL_fontSize;
  font-family: $GLOBAL_fontNameMedium;
  color: $GLOBAL_fontColor;
  padding: 10px 0;
  white-space: pre-wrap;
  line-height: 1.3rem;
}

.buttonActions {
  @include GLOBAL_mixin_flexCenter();
  flex-wrap: wrap;
  gap: 10px;

  .popupButtonOk, .popupButtonClose {
    width: 90px;
    margin: unset;
  }
}
