@import 'globalStyle/GLOBAL';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px 8px;
  width: 100%;

  .title {
    font-family: $GLOBAL_fontName;
    font-size: $GLOBAL_fontSizeHelper;
    line-height: 19px;
    text-align: left;
  }
}

.containerRow {
  flex-direction: row;
  align-items: center;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  color: $GLOBAL_color_gray_2;
}

.titleContainerOption {
  width: 100%;
}

.option {
  color: $GLOBAL_color_blue_4;
  font-size: $GLOBAL_fontSizeAdditional;
  padding-left: 10px;
}

.requiredStar {
  padding-left: 3px;
  cursor: help;
  color: $GLOBAL_color_errorOrCancel;
}
