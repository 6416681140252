﻿// * * * * * Шрифты * * * * *
$GLOBAL_fontName: 'Montserrat-Regular'; // Основной шрифт
$GLOBAL_fontNameMedium: 'Montserrat-Medium'; // Полужирный шрифт
$GLOBAL_fontNameBold: 'Montserrat-SemiBold'; // Жирный шрифт

// * * * * * Базовая палитра * * * * *
$GLOBAL_bodyBgColor: #EEF2F4;
$GLOBAL_backgroundHeader: #535C69;
$GLOBAL_backgroundColorItemHeader: #7B8691;
$GLOBAL_mainBlackTextColor: #000000;
$GLOBAL_mainWhiteTextColor: #FFFFFF;
$GLOBAL_mainWhiteBackgroundColor: #FFFFFF;
$GLOBAL_backgroundPopupContainer: #F8F9FA;

// * * * * * Базовые параметры * * * * *
$GLOBAL_blockBgColor: #FFFFFF;
$GLOBAL_topMenuHeight: 80px;
$GLOBAL_maxContainerWidth: 1715px;
$GLOBAL_maxPageContainerWidth: 1815px;
$GLOBAL_maxContentWidth: 10000px;
$GLOBAL_popupBodyBgColor: rgba(0, 0, 0, 0.8);
$GLOBAL_borderRadiusContainer: 10px;
$GLOBAL_yandexMapHeight: 400px;
$GLOBAL_footerHeight: 60px;
$GLOBAL_footerMobileHeight: 120px;
$GLOBAL_lineHeight: 22px;
$GLOBAL_animationTime: .2s;

// * * * * * z-index * * * * *
$GLOBAL_zIndex_popup: 100000;
$GLOBAL_zIndex_menu: $GLOBAL_zIndex_popup - 90;
$GLOBAL_zIndex_paginate: $GLOBAL_zIndex_popup - 100;
$GLOBAL_zIndex_1: $GLOBAL_zIndex_popup - 1;
$GLOBAL_zIndex_2: $GLOBAL_zIndex_1 - 1;
$GLOBAL_zIndex_3: $GLOBAL_zIndex_2 - 1;
$GLOBAL_zIndex_4: 100;
$GLOBAL_zIndex_5: 10;

// * * * * * Размеры шрифтов * * * * *
$GLOBAL_fontSizeHelper: 12px; // Для подсказок мелким шрифтом
$GLOBAL_fontSizeSecondary: 13px; // Вторичный размер шрифта страницы
$GLOBAL_fontSize: 14px; // Основной размер шрифта страницы
$GLOBAL_fontSizeAdditional: 16px; // Вспомогательный размер шрифта
$GLOBAL_fontSizeBlockContainerTitle: 18px; // Шрифт для блоков
$GLOBAL_fontSizeLargeTitle: 20px; // Шрифт для больших заголовков
$GLOBAL_fontSizeTitle: 24px; //Для заголовков страниц
$GLOBAL_fontSizeBigTitle: 30px;

// * * * * * Цветовая палитра * * * * *
//black
$GLOBAL_primary_text_color: #000000;
$GLOBAL_colorInputBlack: #373737;
$GLOBAL_fontColor: #193b68;
$GLOBAL_fontColorPrimary: #333;
// red
$GLOBAL_color_red_1: #AC0500;
$GLOBAL_color_red_2: #F1222E;
$GLOBAL_color_errorOrCancel: #BF453E;
//green
$GLOBAL_color_green_1: #3CF3B6;
$GLOBAL_color_green_2: #3FE37B;
$GLOBAL_color_green_3: #37ECCA;
$GLOBAL_color_green_4: #65CE9E;
$GLOBAL_color_successOrOk: #0EA65C;
//gray
$GLOBAL_additional_text_color: #DDDDDD;
$GLOBAL_color_disabled: #99AEBB;
$GLOBAL_color_gray_0: #f0fAf8;
$GLOBAL_color_gray_1: #A8B5C9;
$GLOBAL_color_gray_2: #A8B5C9;
$GLOBAL_color_gray_3: #CFD0D2;
$GLOBAL_color_gray_4: #CAD6E7;
$GLOBAL_color_gray_5: #8C929C;
$GLOBAL_color_gray_5: #F3F8FF;
$GLOBAL_color_gray_7: #36393D;
$GLOBAL_color_gray_8: #6A727C;
$GLOBAL_color_gray_10: #DDE8F8;
$GLOBAL_color_gray_11: #EFF3F8;
$GLOBAL_color_gray_12: #99A8BE;
$GLOBAL_color_gray_13: #F0F6FF;
$GLOBAL_color_gray_14: #E6ECF4;
$GLOBAL_color_dividing_line: #F1F4FC;
$GLOBAL_borderColor: #99AEBB;
$GLOBAL_color_gray: #818C99;
$GLOBAL_grayColor: #818C99;
//blue
$GLOBAL_color_blue_1: #03449A;
$GLOBAL_color_blue_2: #4E7CB8;
$GLOBAL_color_blue_3: #839DBF;
$GLOBAL_color_blue_4: #85B1EB;
$GLOBAL_color_edit: #4E7CB8;
$GLOBAL_color_edit_hover: #03449A;

//new
$GLOBAL_color_primary: #52B72F;
$GLOBAL_backgroundTooltipColor: #8C929C;
$GLOBAL_grayColorSecond: #D7D9DC;
$GLOBAL_fontColorOnAnyBg: #FFFFFF;
$GLOBAL_color_headerBackground: #535C69;
$GLOBAL_color_autoFillInput: #DDF9D2;
$GLOBAL_color_blue_2: rgba(25, 59, 104, 0.5);
$GLOBAL_color_border_radius: #DBDCE0;
