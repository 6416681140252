@import 'globalStyle/GLOBAL';

$animationTime: .2s;

.menuMainContainer {
  width: 100%;
  background-color: $GLOBAL_color_headerBackground;
  top: 0;
  left: 0;
  right: 0;
  font-family: $GLOBAL_fontName;
  z-index: $GLOBAL_zIndex_menu;
  display: flex;
  box-shadow: 0 0 6px 1px $GLOBAL_backgroundHeader;
  position: fixed;
  justify-content: center;

  .topMenuMainContainer {
    z-index: inherit;
    position: relative;
    height: 60px;
    width: 100%;
    max-width: $GLOBAL_maxContainerWidth;
    margin: 0 50px;
    padding: 5px 0;
    transition: padding $animationTime ease-in-out;
  }
}

@include mediaSmartphone {
  .menuMainContainer {
    .topMenuMainContainer {
      margin: 0 18px;
    }
  }
}
