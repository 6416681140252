@import 'globalStyle/GLOBAL';

.buttonsContainer {
  $btnMargin: 5px;

  & > button {
    margin: $btnMargin;
  }

  & > a {
    margin: $btnMargin;
  }

  & > span {
    margin: $btnMargin;
  }
}

.buttonsAlignCenter {
  text-align: center;
  @include GLOBAL_mixin_flexCenter();
}

.buttonsAlignLeft {
  text-align: left;
}

.buttonsAlignRight {
  text-align: right;
}
