@import 'globalStyle/GLOBAL';

$animationTime: .2s;

.componentContainer {
  background-color: $GLOBAL_backgroundHeader;
  z-index: $GLOBAL_zIndex_5;
  width: 100%;
  padding: 5px 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 60px;
  margin-top: -60px;
  position: relative;
}

.innerContainer {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  box-sizing: border-box;
  max-width: $GLOBAL_maxContainerWidth;
}

.supportIcon {
  border-radius: 50px;
  background-color: transparent;
  color: $GLOBAL_fontColorOnAnyBg;
  $size: 20px;
  width: $size;
  height: $size;
}

.bottomMenuInfo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  color: $GLOBAL_fontColorOnAnyBg;
  font-family: $GLOBAL_fontNameMedium;
  font-size: $GLOBAL_fontSize;

  & span {
    opacity: 0.8;
  }
}

.numberForTechSupport {
  text-decoration: unset;
  color: $GLOBAL_fontColorOnAnyBg;

  &:hover {
    text-decoration: underline;
  }
}

.container {
  display: flex;
  align-items: center;
  gap: 12px;
  color: $GLOBAL_fontColorOnAnyBg;
  font-family: $GLOBAL_fontNameMedium;
  cursor: pointer;
  transition: color $animationTime ease-in-out;

  svg {
    color: $GLOBAL_fontColorOnAnyBg;
    $size: 20px;
    width: $size;
    height: $size;
    transition: color $animationTime ease-in-out;
  }

  .contactContainer {
    position: relative;
  }

  &:hover {
    color: $GLOBAL_color_autoFillInput;

    svg {
      color: $GLOBAL_color_autoFillInput;
    }
  }
}

@include mediaSmartphone {
  .componentContainer {
    padding: 5px 18px;
  }
}

@include mediaSmallSmartphone {
  .componentContainer {
    height: 120px;
    margin-top: -120px;
  }

  .unAuthComponentContainer {
    height: 60px;
    margin-top: -60px;
  }

  .innerContainer {
    flex-direction: column;
    justify-content: center;
  }

  .bottomMenuInfo {
    align-items: center;
  }
}
