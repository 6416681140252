@import 'globalStyle/GLOBAL';

$animationTime: .2s;

.componentContainer {
  font-family: $GLOBAL_fontNameMedium;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;
  color: $GLOBAL_fontColor;
  line-height: 18px;
  gap: 8px;
  cursor: pointer;
  transition: background-color $animationTime ease-in-out;

  &:hover {
    background-color: $GLOBAL_color_gray_10;
    border-radius: 8px;
  }

  .icoContainer {
    color: $GLOBAL_color_primary;
    width: 20px;
    height: 16px;

    div {
      width: inherit;
      height: inherit;
    }

    svg {
      width: inherit !important;
      height: inherit !important;
      color: inherit;
    }
  }
}

.routerLinkActive {
  background-color: $GLOBAL_color_gray_10;
  border-radius: 8px;
}
