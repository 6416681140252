@import 'globalStyle/GLOBAL';

.bodyPopupContainer {
  @include GLOBAL_mixin_popupBg()
}

.popupWindowContainer {
  box-sizing: border-box;
  margin: 10px;
  border: unset;
  overflow: auto;
  width: 50%;
  min-width: 500px;
  max-width: 550px;
  border-radius: 20px;
  transition: box-shadow 0.3s ease-in-out;
  animation: animationAppearanceIn 0.3s;
  background-color: $GLOBAL_fontColorOnAnyBg;
  box-shadow: 0 4px 8px rgba(48, 116, 205, 0.2);
  padding: 25px;
  max-height: 90%;

  @keyframes animationAppearanceIn {
    from {
      opacity: 0;
      -webkit-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      -webkit-transform: scale(0);
      -ms-transform: scale(0);
      transform: scale(0);
    }
    to {
      opacity: 1;
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  }
}

.popupWindowTitle {
  font-family: $GLOBAL_fontNameBold;
  font-size: $GLOBAL_fontSizeBlockContainerTitle;
  line-height: $GLOBAL_lineHeight;
  text-align: center;
  color: $GLOBAL_fontColor;
  text-transform: unset;
  padding: 0 20px;
}

.contentContainer {
  max-height: 65vh;
  overflow: auto;
  $paddingSize: 30px;
  padding: 10px $paddingSize;
  margin-right: 7px;
  display: grid;
}

.containerButtonClose {
  text-align: right;
}

.buttonClosePopup {
  color: $GLOBAL_color_disabled;
  cursor: pointer;
  font-size: $GLOBAL_fontSizeLargeTitle;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: $GLOBAL_color_errorOrCancel;
  }
}

.topPopupContainer {
  display: flex;
  align-items: normal;
  justify-content: start;
  position: relative;
  flex-direction: column;
}

.resetIcon {
  cursor: pointer;
  padding: 5px;
  background-color: $GLOBAL_color_primary;
  border-radius: 4px;
  color: $GLOBAL_fontColorOnAnyBg;
  width: $GLOBAL_fontSize;
  height: $GLOBAL_fontSize;
  border: 1px solid transparent;
  $animationTime: .2s;
  transition: background-color $animationTime ease-in-out, color $animationTime ease-in-out, border $animationTime ease-in-out;

  &:active,
  &:focus-visible,
  &:focus,
  &:hover {
    background-color: $GLOBAL_fontColorOnAnyBg;
    color: $GLOBAL_color_primary;
    border: 1px solid $GLOBAL_color_primary;
  }
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;

  button:first-child, button:last-child {
    margin: 0 5px 0 0;
  }
}

@media screen and (max-width: 1400px) {
  .popupWindowContainer {
    width: 60%;
  }
}

@include mediaSmartphone {
  .popupWindowContainer {
    width: 100%;
    max-height: 100%;
    height: 100%;
    margin: 0;
    border-radius: unset;
    min-width: unset;
    max-width: unset;
  }

  .contentContainer {
    padding: 10px 15px 20px 15px;
    max-height: unset;
  }
}

@media screen and (max-width: 500px) {
  .popupWindowContainer {
    padding: 12px;
  }

  .popupWindowTitle {
    font-size: $GLOBAL_fontSizeBlockContainerTitle;
  }
}
