﻿@import "./global_variables";

$SVG_PATH: "/img/svg_base64/";
$SVG_PATH_BASE64: "/img/svg_base64/";

// Шрифт по умолчанию
@mixin GLOBAL_mixin_defaultFonts($fontSize: $GLOBAL_fontSize) {
  color: $GLOBAL_fontColor;
  font-family: $GLOBAL_fontName;
  font-size: $fontSize;
}

//Запрещает выделять текст
@mixin GLOBAL_mixin_unselectableText {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// Выравнивание по центру flex
@mixin GLOBAL_mixin_flexCenter($argDisplay: flex) {
  display: $argDisplay;
  align-items: center;
  justify-content: center;
}

// svg как фон
@mixin GLOBAL_mixin_svgBg($svgImgName, $size: 75% 75%, $withoutPosition: false) {
  background-repeat: no-repeat;
  background-size: $size;
  $svgPath: $SVG_PATH+$svgImgName;
  background-image: url($svgPath);

  @if $withoutPosition == false {
    background-position: center;
  }
}

@mixin GLOBAL_mixin_popupBg() {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: $GLOBAL_zIndex_popup;
  @include GLOBAL_mixin_flexCenter();
  animation: animationOpacityIn 0.5s;
  background-color: $GLOBAL_popupBodyBgColor;

  @keyframes animationOpacityIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

@mixin GLOBAL_mixin_inputStyle($withoutWidth: false, $withoutPadding: false) {
  @if ($withoutWidth == false) {
    width: 100%;
  }

  @if ($withoutPadding == false) {
    padding: 10px;
  }

  color: black;
  box-sizing: border-box;
  background-color: $GLOBAL_color_gray_1;
  border: 1px solid $GLOBAL_color_gray_1;
  border-radius: 5px;
  outline: none;
  font-size: $GLOBAL_fontSizeAdditional;
  font-family: $GLOBAL_fontName;
  $animationTime: 0.5s;
  transition: background-color $animationTime ease-in-out, border-color $animationTime ease-in-out;

  &:active,
  &:focus-visible,
  &:focus {
    border-color: $GLOBAL_color_gray_1;
  }

  &:hover {
    border-color: $GLOBAL_color_gray_1;
  }
}

@mixin GLOBAL_mixin_inputStyleError() {
  border-color: $GLOBAL_color_errorOrCancel !important;
}

// Функции
@function GLOBAL_function_toString($value) {
  @return inspect($value);
}

@function GLOBAL_function_strReplace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + GLOBAL_function_strReplace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@function GLOBAL_function_convertColorToStr($color) {
  @return GLOBAL_function_strReplace(GLOBAL_function_toString($color), "#", $replace: "%23");
}

@mixin GLOBAL_mixin_svgBgAsBase64($svgImgName, $svgColor: null) {
  $svgPath: $SVG_PATH_BASE64+$svgImgName;

  @if $svgColor {
    $svgImgColorStr: GLOBAL_function_convertColorToStr($svgColor);
    $svgPath: $svgPath + '?fill='+$svgImgColorStr;
  }

  background-image: url($svgPath);
}

$SVG_PATH_URL: "/img/svg_url/";
@mixin GLOBAL_mixin_svgBgAsUrl($svgImgName) {
  $svgPath: $SVG_PATH_URL+$svgImgName;
  background-image: url($svgPath);
}

@mixin GLOBAL_mixin_inputTypeError() {
  font-size: $GLOBAL_fontSizeHelper;
  color: $GLOBAL_color_errorOrCancel;
  padding-top: 5px;
  text-align: left;
  animation: animation_errorOpacityIn .3s;

  @keyframes animation_errorOpacityIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

@mixin GLOBAL_text_overflow {
  white-space: pre-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin mediaTabletAndSmartphone {
  @media screen and (max-width: 961px) {
    @content;
  }
}

@mixin mediaTablet {
  @media screen and (min-width: 768px) and (max-width: 961px) {
    @content;
  }
}

@mixin mediaSmartphone {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin mediaSmallSmartphone {
  @media screen and (max-width: 450px) {
    @content;
  }
}
