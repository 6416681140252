@import 'globalStyle/GLOBAL';

$animationTime: .2s;

.linkTitle {
  font-family: $GLOBAL_fontNameMedium;
  font-size: $GLOBAL_fontSize;
  color: $GLOBAL_fontColorOnAnyBg;
  line-height: $GLOBAL_lineHeight;
  text-align: left;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.backIcon {
  color: $GLOBAL_color_primary;
  height: 19px;
  width: 10px;
  cursor: pointer;
  border-radius: 10px;
  padding: 5px 9px;
  transition: background-color $animationTime ease-in-out, color $animationTime ease-in-out;
  margin-right: 10px;
  margin-left: -9px;

  &:hover {
    color: $GLOBAL_fontColorOnAnyBg;
    background-color: $GLOBAL_color_primary;
  }
}
