@import 'globalStyle/GLOBAL';

.componentContainer {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.textarea {
  background-color: $GLOBAL_fontColorOnAnyBg;
  font-family: $GLOBAL_fontNameMedium;
  font-size: $GLOBAL_fontSize;
  color: $GLOBAL_fontColor;
  width: 100%;
  box-sizing: border-box;
  font-weight: 500;
  border: 1px solid $GLOBAL_color_gray_4;
  border-radius: 5px;
  padding: 10px 15px;
  line-height: 19px;
  outline: none;
  resize: vertical;
  min-height: 180px;
  height: 180px;
  $animationTime: .2s;
  transition: background-color $animationTime ease-in-out, border-color $animationTime ease-in-out;

  &:hover {
    border-color: $GLOBAL_color_primary;
  }

  &:active,
  &:focus-visible,
  &:focus {
    background-color: $GLOBAL_color_gray_5;
    border-color: $GLOBAL_color_primary;
  }
}

.textareaStatusError {
  @include GLOBAL_mixin_inputStyleError();
}

.errorText {
  @include GLOBAL_mixin_inputTypeError();
}
