@import 'globalStyle/GLOBAL';

$animationTimeContainer: .5s;

.popupBackground {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $GLOBAL_popupBodyBgColor;
  top: 0;
  left: 0;
  z-index: $GLOBAL_zIndex_popup;
  @include GLOBAL_mixin_flexCenter();
  transition: background-color $animationTimeContainer ease-in-out;
}

.popupBody {
  background-color: $GLOBAL_fontColorOnAnyBg;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: 0 0 5px 0 $GLOBAL_popupBodyBgColor;
  margin: 15px;
  width: 400px;
  min-width: 360px;
  max-height: 90%;
  border-radius: 20px;
  overflow-y: auto;
  animation: animation_popupOpacityIn $animationTimeContainer;
}

.popupContentContainerWithCloseButton {
  padding: 10px 20px 10px 20px;
}

.popupContentContainer {
  padding: 25px;
}

.containerButtonClose {
  text-align: right;
  padding: 10px 10px 0 0;
}

.documentBodyOverflowHidden {
  overflow-x: hidden;
  overflow-y: scroll;
}

@keyframes animation_popupOpacityIn {
  from {
    $animationTime: .2s;
    opacity: 0;
    -webkit-transition: all $animationTime ease-in-out;
    -o-transition: all $animationTime ease-in-out;
    transition: all $animationTime ease-in-out;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.resetIcon {
  font-size: $GLOBAL_fontSizeBlockContainerTitle;
  cursor: pointer;
  padding: 5px;
  background: $GLOBAL_color_primary;
  border-radius: 4px;
  color: $GLOBAL_fontColorOnAnyBg;
  width: $GLOBAL_fontSize;
  height: $GLOBAL_fontSize;
  border: 1px solid transparent;
  $animationTime: .2s;
  transition: background-color $animationTime ease-in-out, color $animationTime ease-in-out, border $animationTime ease-in-out;

  &:active,
  &:focus-visible,
  &:focus,
  &:hover {
    background-color: $GLOBAL_fontColorOnAnyBg;
    color: $GLOBAL_color_primary;
    border: 1px solid $GLOBAL_color_primary;
  }
}
