@import 'globalStyle/GLOBAL';

.icoContainer {
  font-size: 80px;
  text-align: center;
}

.icoSuccessColor {
  color: $GLOBAL_color_successOrOk;
}

.messageContainer {
  text-align: center;
  font-size: $GLOBAL_fontSize;
  font-family: $GLOBAL_fontNameMedium;
  color: $GLOBAL_fontColor;
  padding: 10px 0;
  white-space: pre-wrap;
  line-height: 1.3rem;
}

.loadingTextContainer {
  text-align: center;
  word-break: break-word;
  font-size: $GLOBAL_fontSize;
  font-family: $GLOBAL_fontNameMedium;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.alertTextContainer {
  font-family: $GLOBAL_fontNameMedium;
  font-size: $GLOBAL_fontSize;
  text-align: center;
  padding: 5px;
  word-break: break-word;
  white-space: pre-wrap;
  margin-bottom: 7px;
}

.linkAsButton {
  margin: 0 auto;
  width: fit-content;
}
