@import 'globalStyle/GLOBAL';

.yandexMapContainer {
  height: $GLOBAL_yandexMapHeight;
  margin-top: -$GLOBAL_yandexMapHeight;
  bottom: $GLOBAL_footerHeight;
  position: relative;
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

.yandexMapInner {
  height: inherit;
  width: inherit;
}

@media screen and (max-width: 450px) {
  .yandexMapContainer {
    bottom: $GLOBAL_footerMobileHeight;
  }
}

:global {
  .ymaps-2-1-79-map ymaps {
    font-family: $GLOBAL_fontNameMedium;
    font-size: $GLOBAL_fontSizeHelper;
  }
}
