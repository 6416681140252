@import 'globalStyle/GLOBAL';

@mixin mixin_standardButton($textColor, $bgColor) {
  color: $textColor;
  background-color: $bgColor;

  &:hover {
    background-color: $GLOBAL_fontColorOnAnyBg;
    border-color: $GLOBAL_color_primary;
    color: $GLOBAL_color_primary;
  }
}

.iconLeft {
  padding-right: 5px;
}

.iconRight {
  padding-left: 5px;
}

.buttonFontSize {
  font-size: 13px;
}

.button {
  border: 2px solid transparent;
  border-radius: 8px;
  font-size: 13px;
  font-family: $GLOBAL_fontNameMedium;
  line-height: 15px;
  cursor: pointer;
  justify-content: center;
  text-decoration: none;
  padding: 10px 40px;
  @include GLOBAL_mixin_flexCenter();
  @include GLOBAL_mixin_unselectableText;
  $animationTime: .2s;
  transition: background-color $animationTime ease-in-out, color $animationTime ease-in-out, border-color $animationTime ease-in-out;
  outline: none;
  margin: 0 auto;
}

.buttonOk {
  @include mixin_standardButton($GLOBAL_fontColorOnAnyBg, $GLOBAL_color_primary);
}

.buttonPrimary {
  color: $GLOBAL_fontColorOnAnyBg;
  background-color: #FFB800;

  &:hover {
    background-color: $GLOBAL_fontColorOnAnyBg;
    border: 2px solid #FFB800;
    color: #FFB800;
  }
}

.buttonDisabled {
  @include mixin_standardButton($GLOBAL_color_gray_2, $GLOBAL_fontColorOnAnyBg);
  border: 1px solid $GLOBAL_color_gray_2;
}

.buttonCancel {
  @include mixin_standardButton($GLOBAL_fontColor, #F3F8FF);
}
