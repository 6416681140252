@import 'globalStyle/GLOBAL';

$animationTime: 2s;

.container {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: $GLOBAL_zIndex_1;
  margin-top: 0;
  left: 0;
  top: 0;
  background-color: $GLOBAL_bodyBgColor;
}

.innerContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.spinner {
  animation: rotate $animationTime linear infinite;
  z-index: inherit;
  height: 40px;

  & .path {
    stroke: $GLOBAL_color_primary;
    stroke-linecap: round;
    animation: dash 2s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
