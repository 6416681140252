@import 'globalStyle/GLOBAL';

.checkbox {
  margin-bottom: 0;
}

.checkbox, label {
  cursor: pointer;
}

/* для элемента input c type="checkbox" */
.customCheckbox > input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* для элемента label, связанного с .custom-checkbox */
.customCheckbox > span {
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-family: $GLOBAL_fontName;
  font-size: $GLOBAL_fontSizeSecondary;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  color: $GLOBAL_fontColor;
}

/* создание в label псевдоэлемента before со следующими стилями */
.customCheckbox > span::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #ADB5BD;
  border-radius: 0.25em;
  margin-right: 12px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

/* стили при наведении курсора на checkbox */
.customCheckbox > input:not(:disabled):not(:checked) + span:hover::before {
  border-color: $GLOBAL_color_primary;
}

/* стили для активного чекбокса (при нажатии на него) */
.customCheckbox > input:not(:disabled):active + span::before {
  background-color: #B3D7FF;
  border-color: #B3D7FF;
}

/* стили для чекбокса, находящегося в фокусе */
.customCheckbox > input:focus + span::before {
  /*box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);*/
}

/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.customCheckbox > input:focus:not(:checked) + span::before {
  border-color: #80BDFF;
}

/* стили для чекбокса, находящегося в состоянии checked */
.customCheckbox > input:checked + span::before {
  border-color: $GLOBAL_color_primary;
  background-color: $GLOBAL_color_primary;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

/* стили для чекбокса, находящегося в состоянии disabled */
.customCheckbox > input:disabled + span::before {
  background-color: #E9ECEF;
}

.disabledCheckboxContainer {
  pointer-events: none;
  cursor: default;
}
