@import 'globalStyle/GLOBAL';

$animationTime: .2s;
$paddingTop: 100px;

.contentPageContainer, .unAuthContentPageContainer {
  background-color: transparent;
  margin: 0 auto;
  transition: padding $animationTime ease-in-out;
  max-width: $GLOBAL_maxPageContainerWidth;
  box-sizing: border-box;
  min-height: 100%;
  position: relative;
}

.contentPageContainer {
  padding: $paddingTop 0 $GLOBAL_yandexMapHeight + $GLOBAL_footerHeight 0;
}

.unAuthContentPageContainer {
  padding: $paddingTop 0 $GLOBAL_footerHeight 0;
}

@include mediaSmallSmartphone {
  .contentPageContainer {
    padding-bottom: $GLOBAL_yandexMapHeight + $GLOBAL_footerMobileHeight;
  }

  .unAuthContentPageContainer {
    padding-bottom: $GLOBAL_footerMobileHeight;
  }
}
