@import "./GLOBAL.scss";

@font-face {
  font-family: "Montserrat-Regular";
  src: url("./fonts/Montserrat-Regular.woff") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("./fonts/Montserrat-Medium.woff") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./fonts/Montserrat-SemiBold.woff") format("truetype");
  font-style: normal;
  font-weight: normal;
}

html, body {
  margin: 0;
  height: 100%;
  @include GLOBAL_mixin_defaultFonts;
}

body {
  background-color: $GLOBAL_bodyBgColor;
  overflow-x: hidden;
  overflow-y: scroll;
}

:global {
  #Root {
    max-width: $GLOBAL_maxContentWidth;
    height: 100%;
  }

  html {
    height: 100%;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #E9F2FF;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(150, 255, 84, 0.3);
    border-radius: 20px;
  }

  .ReactModal__Overlay {
    z-index: $GLOBAL_zIndex_popup !important;
  }

  .slick-dots {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0 0 0 -8px;
    padding: 0;
  }

  .slick-dots li {
    margin: 0 10px;
  }

  .slick-dots li button::before {
    background-color: $GLOBAL_color_gray_2;
    color: $GLOBAL_color_gray_2;
    height: 8px;
    width: 35px;
    overflow: hidden;
    border: none;
    border-radius: 4px;
    opacity: 0.8;
    $animationTime: .3s;
    transition: color $animationTime ease-in-out, background-color $animationTime ease-in-out;
  }

  .slick-dots li.slick-active button::before {
    background: $GLOBAL_color_primary;
    color: $GLOBAL_color_primary;
  }
}
