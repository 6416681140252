@import 'globalStyle/GLOBAL';

.componentContainer {
  padding: 0 10px;
  @include GLOBAL_mixin_flexCenter();

  & > div {
    max-width: 400px;
  }
}

.textContainer {
  text-align: center;
  font-family: $GLOBAL_fontNameMedium;
  white-space: pre-wrap;
  line-height: 18px;
}
