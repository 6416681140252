@import 'globalStyle/GLOBAL';

.icoContainer {
  width: 30px;
  height: 41px;

  svg {
    width: inherit;
    height: inherit;
    color: $GLOBAL_fontColorOnAnyBg;
  }
}
