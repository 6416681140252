@import 'globalStyle/GLOBAL';

@mixin mediaHeaderOnlyIcons {
  @media screen and (max-width: 850px) { @content; }
}

$animationTime: .2s;

.componentContainer {
  font-size: $GLOBAL_fontSize;
  font-family: $GLOBAL_fontNameMedium;
  color: $GLOBAL_fontColorOnAnyBg;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;
  transition: background-color $animationTime ease-in-out, border-bottom $animationTime ease-in-out;
  white-space: break-spaces;
  line-height: 19px;
  gap: 12px;

  span {
    position: relative;

    &::after {
      position: absolute;
      content: "";
      width: 0;
      height: 2px;
      background-color: $GLOBAL_color_primary;
      left: 50%;
      bottom: -5px;
      transition: all $animationTime ease-in-out;
      border-radius: 28px;
    }
  }

  &:hover {
    span::after {
      cursor: pointer;
      width: 100%;
      left: 0;
    }
  }
}

.routerLinkActive {
  span::after {
    cursor: pointer;
    width: 100%;
    left: 0;
  }
}

@include mediaHeaderOnlyIcons {
  .icoContainer {
    padding: 0;
  }
}

@media screen and (max-width: 1295px) {
  .componentContainer {
    span {
      display: none;
    }
  }
}
